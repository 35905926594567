import moment from "moment";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, FormGroup, Label, Row, Table } from "reactstrap";
import { ExchangePartnerIndexDetailsComponent } from "../../components/ExchangePartnerIndexDetailsComponent";
import LoaderComponent from "../../components/LoaderComponent";
import PagerComponent from "../../components/PagerComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { ExchangePartnerIndexDto } from "../../lib/drivers/dto/exchange-partners/ExchangePartnerIndexDto";
import { ExchangePartnerTradingDistributionDto } from "../../lib/drivers/dto/exchange-partners/ExchangePartnerTradingDistributionDto";
import withRouterHook from "../../withRouterHook";

type ExchangePartnerIndexListPageParams = ReturnType<typeof useParams> & {
  exchangePartnerId: string;
};

type ExchangePartnerIndexListPageProps = {
  location?: ReturnType<typeof useLocation>;
  params?: ExchangePartnerIndexListPageParams;
};

type Filter = {
  exchangePartnerIndexId?: string;

  page: number;
  pageSize: number;
  total: number;
};

type ExchangePartnerIndexListPageState = {
  isLoading: boolean;
  startDate: Date;
  columns: any[];

  year: number;
  month: number;

  entity: ExchangePartnerIndexDto;
  list: ExchangePartnerTradingDistributionDto[];

  // filter
  filter: Filter;
};

class ExchangePartnerIndexListPage extends React.Component<ExchangePartnerIndexListPageProps, ExchangePartnerIndexListPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: ExchangePartnerIndexListPageState = {
    isLoading: true,
    startDate: new Date(),
    columns: [],

    year: moment().year(),
    month: moment().month() + 1,

    entity: null,
    list: [],

    // filter
    filter: {
      page: 1,
      pageSize: 128,
      total: 0
    }
  };

  // #region React Events
  componentDidMount = async () => {
    if (this.props.params.exchangePartnerId) {
      await this.loadData();
    }
  };

  componentDidUpdate = async (prevProps: ExchangePartnerIndexListPageProps, prevState: ExchangePartnerIndexListPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Functions
  loadData = async () => {
    const queryParams = new URLSearchParams(this.props.location.search);

    if (queryParams.get("year") && queryParams.get("month")) {
      await this.setState((prevState) => {
        return {
          year: parseInt(queryParams.get("year")),
          month: parseInt(queryParams.get("month"))
        };
      });
    }

    const entity: ExchangePartnerIndexDto = await PortCentralServer.Repo.client.getExchangePartnerIndexByPeriod(
      this.props.params.exchangePartnerId,
      this.state.year,
      this.state.month
    );

    if (entity && entity.exchangePartnerIndexId) {
      await this.setState((prevState) => {
        return {
          entity: entity
        };
      });

      const filter: Filter = {
        exchangePartnerIndexId: entity.exchangePartnerIndexId,

        page: parseInt(queryParams.get("page") || "1", 10),
        pageSize: parseInt(queryParams.get("pageSize") || "128", 10),
        total: 0
      };

      const offset = (filter.page - 1) * filter.pageSize;

      const response = await PortCentralServer.Repo.client.filterExchangePartnerTradingDistributionList({
        exchangePartnerIndexId: filter.exchangePartnerIndexId,

        limit: filter.pageSize,
        offset: offset
      });

      if (response) {
        filter.total = response.total;

        await this.setState((prevState) => {
          return {
            filter: filter,
            list: response.data,
            isLoading: false
          };
        });
      } else {
        await this.setState((prevState) => {
          return {
            isLoading: false
          };
        });
      }
    } else {
      // empty result and remove loading
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };
  // #endregion

  // #region Handlers
  async handleSyncClick(e: React.FormEvent<HTMLButtonElement>) {
    e.preventDefault();

    await this.setState((prevState) => {
      return {
        isLoading: true
      };
    }); 

    if (this.props.params.exchangePartnerId) {
      await await PortCentralServer.Repo.client.syncExchangePartnerIndex({
        exchangePartnerId: this.props.params.exchangePartnerId,
        year: this.state.year,
        month: this.state.month
      });

      await this.loadData();
    }
  }
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  // #region Renders
  handlePageSizeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPageSize = parseInt(e.target.value, 10);
    await this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          pageSize: newPageSize,
          page: 1 // Reset to page 1 when page size changes
        }
      }),
      () => {
        // Assuming you want to reload data and update URL here
        // this.props.history.push(`?page=1&pageSize=${newPageSize}`);
        // this.loadData();
      }
    );
  };
  // #endregion

  render() {
    const { year: filterYear, month: filterMonth } = this.state;
    // Calculate previous month and year
    const prevMonth = filterMonth === 1 ? 12 : filterMonth - 1;
    const prevYear = filterMonth === 1 ? filterYear - 1 : filterYear;

    // Calculate next month and year
    const nextMonth = filterMonth === 12 ? 1 : filterMonth + 1;
    const nextYear = filterMonth === 12 ? filterYear + 1 : filterYear;

    const currentYear = moment().year();
    const currentMonth = moment().month() + 1;

    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Exchange Partners" breadcrumbItem="Index" />

              <Row className="mb-3">
                <Col>
                  <a className="btn btn-primary" href={`?year=${prevYear}&month=${prevMonth}`}>
                    Prev Month
                  </a>

                  <span style={{ marginLeft: "10px", marginRight: "10px" }}>{`${filterYear}-${filterMonth}`}</span>

                  <a className="btn btn-primary" href={`?year=${nextYear}&month=${nextMonth}`}>
                    Next Month
                  </a>

                  {(this.state.year !== currentYear || this.state.month !== currentMonth) && (
                    <a className="btn btn-sm btn-outline-info mx-2" href={`?year=${currentYear}&month=${currentMonth}`}>
                      Go to Current Month
                    </a>
                  )}
                </Col>

                <Col className="" style={{ textAlign: "right" }}>
                  <Link className="btn w-md btn-warning" to={`/exchange-partners/payments?partner=${this.state.entity?.exchangePartnerId}`}>
                    Payments
                  </Link>{" "}
                  <Button color="primary" className="w-md" onClick={(e) => this.handleSyncClick(e)}>
                    Sync current month
                  </Button>
                </Col>
              </Row>

              {this.state.entity && (
                <Row>
                  <Col xl={12}>
                    <ExchangePartnerIndexDetailsComponent entity={this.state.entity} />
                  </Col>
                </Row>
              )}

              <Row>
                <Col xl={12}>
                  {this.state.isLoading ? (
                    <LoaderComponent />
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <h5 className="fw-semibold">Trades Distribution</h5>
                          <Row>
                            <Col xl={12}>
                              <div className="table-responsive">
                                <Table className="table table-bordered m-0">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>contractName</th>
                                      <th>totalVolumeABook</th>
                                      <th>totalVolumeBBook</th>
                                      <th>realizedPnlABook</th>
                                      <th>realizedPnlBBook</th>
                                      <th>totalVolume</th>
                                      <th>profitShare</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(this.state.list || []).map((item: ExchangePartnerTradingDistributionDto, key: number) => (
                                      <tr key={key}>
                                        <td className="nowrap"></td>
                                        <td>{item.contractName}</td>
                                        <td>{item.totalVolumeABook}</td>
                                        <td>{item.totalVolumeBBook}</td>
                                        <td>{item.realizedPnlABook}</td>
                                        <td>{item.realizedPnlBBook}</td>
                                        <td className="text-warning">{item.totalVolume}</td>
                                        <td className="text-danger">{item.profitShare}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <FormGroup>
                                  <Label for="pageSizeSelect">Page Size</Label>
                                  <select name="pageSize" id="pageSizeSelect" onChange={this.handlePageSizeChange} value={this.state.filter.pageSize}>
                                    <option value="32">32</option>
                                    <option value="64">64</option>
                                    <option value="128">128</option>
                                  </select>
                                </FormGroup>
                                <PagerComponent currentPage={this.state.filter.page} pageSize={this.state.filter.pageSize} totalItems={this.state.filter.total} />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(ExchangePartnerIndexListPage);
